import { useContext } from 'react';
import { PaymentsContext } from 'contexts/Payments';
import { Spinner } from 'assets/milo';
import { sortPaymentsByDate } from 'utils/helpers/sortPaymentsByDate';
import PaymentsHistoryPlaceholder from './PaymentsHistoryPlaceholder';
import styles from './PaymentsTable.module.css';
import MobileTable from './MobileTable';
import DesktopTable from './DesktopTable';
import { useTranslation } from 'react-i18next';
import { LD_FLAGS } from 'constants/launchdarklyFlags';
import { useFlagValue } from 'hooks/useFeatureFlags';
import ExportPayHistoryButton from '../ExportPayHistoryButton';

const PaymentsTable = () => {
  const paymentsContext = useContext(PaymentsContext);
  const paymentsData = paymentsContext?.paymentsData;
  const { t } = useTranslation();
  const isExportCsvEnabled = useFlagValue(LD_FLAGS.exportCsv);

  let completedPayments = paymentsData?.completedPayments?.filter((payment) => (
    payment.details.transferSettledAt && payment.details.disbursementAmountActual
  ))

  completedPayments = sortPaymentsByDate(completedPayments);

  if (!paymentsData?.currency) {
    return <Spinner className={styles.spinner} />
  }

  const paymentHistoryTableTitle = (
    <div className={styles.tableTitle}>
      {t('dashboard.paymentHistory.title1')}
    </div>
  )

  return (
    paymentsData?.currency && completedPayments && completedPayments.length > 0 ? (
      <div className={styles.paymentsTable}>
        {isExportCsvEnabled ? (
          <div className={styles.tableTitleContainer}>
            {paymentHistoryTableTitle}
            <ExportPayHistoryButton data={completedPayments} />
          </div>
        ) : paymentHistoryTableTitle}
        <MobileTable completedPayments={completedPayments} />
        <DesktopTable completedPayments={completedPayments} currency={paymentsData.currency} />
      </div>
    ) : (
      <PaymentsHistoryPlaceholder />
    )
  )
};

export default PaymentsTable;
