import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Button, LogoIcon } from 'assets/milo';
import { CaretDownIcon, HelpIcon } from 'components/Icons';
import { nameConstructor } from 'utils/helpers/nameConstructor';

import {
  QUERY_CURRENT_USER,
  QueryCurrentUserResType,
  GET_CURRENT_USER_QUERY_NAME
} from 'graphql/queries/UserQueries';
import {
  QUERY_CURRENT_MEMBER_DETAILS,
  QueryCurrentMemberDetailsResType,
  GET_CURRENT_MEMBER_DETAILS_QUERY_NAME
} from 'graphql/queries/MemberDetailQueries';
import useLogin from 'hooks/useLogin';
import useLogout from 'hooks/useLogout';

import styles from './AppHeader.module.css';
import useQueryWithErrors from 'hooks/useQueryWithErrors';
import { GET_MEMBER_ONBOARDING_QUERY_NAME, QUERY_MEMBER_ONBOARDING } from '../../graphql/queries/MemberOnboardingQueries';
import { datadogRum } from '@datadog/browser-rum';
import { OKTA_LOGIN_PATH } from 'appConstants';
import SliderTabBar from 'components/SliderTabBar';
import { documentsEnabled } from 'utils/helpers/flags';
import { getIdentityServiceUrl } from 'utils/helpers/identityServiceUrl';
import { TABS } from 'views/DashboardView/constants';
import { useFlagValue } from 'hooks/useFeatureFlags';
import { LD_FLAGS } from 'constants/launchdarklyFlags';

const AppHeader = () => {
  const { t } = useTranslation();
  const { isUserLoggedIn } = useLogin();
  const { userLogout } = useLogout();
  const location = useLocation();
  const hasRedirectedToDashboard = useRef(false);
  const [displayName, setDisplayName] = useState<string>();

  const isDashboardView = location.pathname.includes('dashboard')

  const isContractorProfileEnabled = useFlagValue(LD_FLAGS.profile);
  const isContractorAccountSettingsEnabled = useFlagValue(LD_FLAGS.accountSettings);

  const { data: memberData, refetch: refetchMemberData } = useQueryWithErrors<QueryCurrentMemberDetailsResType>(
    GET_CURRENT_MEMBER_DETAILS_QUERY_NAME,
    QUERY_CURRENT_MEMBER_DETAILS,
    {
      skip: !isUserLoggedIn()
    }
  );

  const { data: userData } = useQueryWithErrors<QueryCurrentUserResType>(
    GET_CURRENT_USER_QUERY_NAME,
    QUERY_CURRENT_USER,
    {
      onCompleted: (data) => {
        const user = data.currentUser
        datadogRum.setUser({
          id: user.id
        })
      }
    }
  );

  const { data: memberOnboardingData, refetch: refetchMemberOnboardingData } = useQueryWithErrors(
    GET_MEMBER_ONBOARDING_QUERY_NAME,
    QUERY_MEMBER_ONBOARDING
  );

  useEffect(() => {
    if (memberData?.currentMemberDetails?.lastName) {
      setDisplayName(nameConstructor(memberData.currentMemberDetails))
    } else if (userData?.currentUser?.username) {
      setDisplayName(userData.currentUser.username)
    }
  }, [memberData, userData]);

  useEffect(() => {
    if (memberData?.currentMemberDetails?.memberId && userData?.currentUser) {
      const pendoConfig = {
        visitor: {
          id: userData.currentUser.id,
          memberId: memberData.currentMemberDetails.memberId
        }
      };
      (window as any).pendo.initialize(pendoConfig)
    }
  }, [memberData, userData])

  useEffect(() => {
    // When user completes onboarding, refetch member and onboarding data
    // to update user's name and nav dropdown items.
    if (!hasRedirectedToDashboard.current &&
          location.pathname.includes('dashboard') &&
          !memberData?.currentMemberDetails?.lastName
    ) {
      refetchMemberData();
      refetchMemberOnboardingData();
      hasRedirectedToDashboard.current = true
    }
  }, [location.pathname]);

  return (
    <header className={styles.header}>
      <nav>
        <Link className={styles.logoLink} to='/'>
          <LogoIcon variant='on-dark' className={styles.logo} />
        </Link>

        {documentsEnabled() && isDashboardView && (
          <div className={styles.sliderTabBarContainer}>
            <SliderTabBar tabs={TABS} />
          </div>
        )}

        <div className={styles.navRight}>
          <div className={styles.navPrimary}>
            {/* TODO: This is where the main navigational links/buttons live. */}
            <div tabIndex={0} className={styles.help} >
              <div className={styles.helpButton}>
                <>
                  <HelpIcon className={styles.helpIcon} />
                  {'\u00A0'}
                  {t('header.help.help')}
                </>
              </div>
              <div className={styles.helpContent}>
                <p className={styles.helpContentHeader}>{t('header.help.needHelp')}</p>
                <p className={styles.helpContentBody}>{t('header.help.helpBody')}
                  <Link className={styles.link} to='mailto:contractor-support@justworks.com'>
                    contractor-support@justworks.com
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <div className={styles.navSecondary}>
            {
              isUserLoggedIn() ? (
                <div tabIndex={0} className={styles.dropdown}>
                  <div className={styles.dropdownButton} data-dd-privacy='mask'>
                    {(displayName) && (
                      <>
                        <span className={styles.displayName}>{displayName}</span>
                        {'\u00A0'}
                        <CaretDownIcon className={styles.dropdownIcon} />
                      </>
                    )}
                  </div>
                  <div className={styles.dropdownContent}>
                    {
                      isContractorProfileEnabled &&
                      memberOnboardingData?.memberOnboarding?.details?.workflowState === 'complete' && (
                        <div className={styles.dropdownContentOption}>
                          <Link to={'/profile'} tabIndex={0} className={styles.dropdownContentLink}>
                            Profile
                          </Link>
                        </div>
                      )
                    }
                    {
                      memberOnboardingData?.memberOnboarding?.details?.workflowState === 'complete' && (
                        <div className={styles.dropdownContentOption}>
                          <Link to={'/bank-account'} tabIndex={0} className={styles.dropdownContentLink}>
                            Bank account
                          </Link>
                        </div>
                      )
                    }
                    {
                      isContractorAccountSettingsEnabled && (
                        <div className={styles.dropdownContentOption}>
                          <Link to={getIdentityServiceUrl()} target='_blank' tabIndex={0} className={styles.dropdownContentLink}>
                            Account settings
                          </Link>
                        </div>
                      )
                    }
                    <div className={styles.dropdownContentOption}>
                      <Button className={styles.dropdownContentLink} tabIndex={1} id='logout' mode='text' onClick={userLogout}>
                        {t('member.logout')}
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <Link className={styles.link} to={OKTA_LOGIN_PATH}>{t('member.login')}</Link>
              )
            }
          </div>
        </div>
      </nav>
    </header>
  );
};

AppHeader.defaultProps = {
  onlyLogo: false
}

export default AppHeader;
