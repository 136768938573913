import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import usePageTitle from 'hooks/usePageTitle';
import useQueryWithErrors from 'hooks/useQueryWithErrors';

import VerticalStepper from 'components/VerticalStepper';
import PersonaAccess from 'views/PersonaAccessView';
import { Spinner } from 'assets/milo';
import styles from '../Onboarding.module.css';
import { OnboardingContext } from 'contexts/Onboarding'

import { GET_MEMBER_ONBOARDING_QUERY_NAME, QUERY_MEMBER_ONBOARDING } from 'graphql/queries/MemberOnboardingQueries';
import { GET_CURRENT_MEMBER_DETAILS_QUERY_NAME, QUERY_CURRENT_MEMBER_DETAILS, QueryCurrentMemberDetailsResType } from 'graphql/queries/MemberDetailQueries';

import { PersonaStatusTypes } from 'utils/types/types';
import { OnboardingSteps, mapWorkflowToEnum } from '../utils/constants';
import { PageHeader } from '@justworkshr/milo-core';

const OnboardingPersona = () => {
  const { t } = useTranslation();
  usePageTitle(t('onboarding.onboarding'));

  const navigate = useNavigate();
  const [skipQuery, setSkipQuery] = useState<boolean>(true)

  const { data, loading } = useQueryWithErrors(
    GET_MEMBER_ONBOARDING_QUERY_NAME,
    QUERY_MEMBER_ONBOARDING,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const workflowState = data?.memberOnboarding?.details?.workflowState
  useEffect(() => {
    if (workflowState === 'complete') {
      navigate('/dashboard');
    }
  }, [data])

  const onboardingContext = useContext(OnboardingContext)

  const backStep = () => {
    navigate('/onboarding/payment-info');
  };

  const handleStepClick = (stepNumber:number) => {
    if (stepNumber === OnboardingSteps.personalInfo) {
      navigate('/onboarding/personal-info');
    }
    if (stepNumber === OnboardingSteps.bankingInfo) {
      navigate('/onboarding/payment-info');
    }
  }

  useQueryWithErrors<QueryCurrentMemberDetailsResType>(
    GET_CURRENT_MEMBER_DETAILS_QUERY_NAME,
    QUERY_CURRENT_MEMBER_DETAILS,
    {
      skip: !(onboardingContext?.isOnboarded && !skipQuery),
      // continue to query account status until persona webhook is processed
      pollInterval: 1000,
      onCompleted: (data) => {
        const { currentMemberDetails: { accountStatus } } = data
        if (accountStatus) {
          setTimeout(() => {
            if (accountStatus?.includes(PersonaStatusTypes.enabled)) {
              navigate('/dashboard?onboarding_success=true');
            } else {
              navigate('/dashboard');
            }
            window.scrollTo(0, 0);
          }, 3000)
        }
      },
      onError: () => {
        setTimeout(() => {
          navigate('/dashboard');
          window.scrollTo(0, 0);
        }, 3000)
      },
      fetchPolicy: 'no-cache'
    }
  )

  const finish = () => {
    onboardingContext?.setIsOnboarded(true);
    setSkipQuery(false);
  };

  if (loading) { return <Spinner variation='black' /> }

  return (
    <div className={styles.content}>
      <div className={styles.container} data-testid='onboarding-container'>
        <aside>
          <VerticalStepper
            steps={[
              t('onboarding.yourProfile'),
              t('onboarding.paymentInfo'),
              t('onboarding.persona')
            ]}
            currentStep={OnboardingSteps.persona}
            onClick={handleStepClick}
            status={mapWorkflowToEnum[workflowState]}
          />
        </aside>
        <div>
          <PageHeader
            className={styles.pageHeader}
            title={t('persona.verifyIdentity')}
            border={false}
          />
          <PersonaAccess
            isOnboarded={onboardingContext?.isOnboarded}
            onCompleted={finish}
            onBack={backStep}
          />
        </div>
      </div>
    </div>
  );
};

export default OnboardingPersona;
