import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import usePaymentInvitation from 'hooks/usePaymentInvitation';
import useQueryWithErrors from 'hooks/useQueryWithErrors';
import {
  GET_CURRENT_COMPANY_DETAILS_QUERY_NAME,
  QueryCurrentCompanyDetailByIdResType,
  QUERY_CURRENT_COMPANY_DETAILS_BY_ID
} from 'graphql/queries/CompanyQueries';
import CreateAccountForm from './components/CreateAccountForm';
import { Spinner } from 'assets/milo';
import styles from './CreateAccount.module.css';
import { REACT_APP_API_HOST } from 'appConstants';

const AccountCreation = () => {
  const { t } = useTranslation();
  const oktaLoginPath = `${REACT_APP_API_HOST}/v1/_login`
  const [searchParams] = useSearchParams();
  const {
    data: { getPaymentInvitationById: paymentInvitation } = { getPaymentInvitationById: undefined },
    loading: loadingPaymentInvitation
  } = usePaymentInvitation(
    searchParams.get('payment_invitation')
  );
  const {
    data: { getCurrentCompanyDetailsById: companyDetails } = { getCurrentCompanyDetailsById: { name: 'your new company' } },
    loading: loadingCompany
  } = useQueryWithErrors<QueryCurrentCompanyDetailByIdResType>(
    GET_CURRENT_COMPANY_DETAILS_QUERY_NAME,
    QUERY_CURRENT_COMPANY_DETAILS_BY_ID,
    {
      variables: { id: paymentInvitation?.companyId },
      skip: !paymentInvitation
    }
  )

  if (!paymentInvitation || loadingPaymentInvitation || loadingCompany) {
    return <Spinner variation='black' />;
  }

  return (
      <>
        <h1 className={styles.header}>{t('member.createAccount')}</h1>
        <p className={styles.subheader}>
          <Trans i18nKey='account.subheader' values={{ companyName: companyDetails.name }}>
            <b>{companyDetails.name}</b>
          </Trans>
        </p>

        <CreateAccountForm
          paymentInvitationId={paymentInvitation?.id || ''}
          countryCode={paymentInvitation?.details?.countryCode || ''}
        />

        <div className={styles.alreadyMember}>
          <p className={styles.secondaryHeader}>{t('account.alreadyMember')} <Link className={styles.link} to={oktaLoginPath}>{t('member.logIn')}</Link></p>
          <p>{t('account.logInToStart')}</p>
        </div>
      </>
  );
};

export default AccountCreation;
