import { ReactElement, JSXElementConstructor, useContext, useState } from 'react';
import { Formik, FormikValues, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';

import { CheckboxInput, Spinner } from 'assets/milo';

import { generateFormValidations, AutoFormField } from 'components/Forms/AutoForm';
import { FormType } from 'utils/types/formTypes';
import FormFieldErrorMessage from 'components/Forms/FormFieldErrorMessage';
import FormStyles from 'assets/styles/FormStyles.module.css';

import useMutationFormErrors from 'hooks/useMutationFormErrors';
import { SupportedCountriesContext } from 'contexts/SupportedCountries';
import { GET_BANK_ACCOUNT_FORM_QUERY_NAME, QueryBankAccountFormResType, QUERY_BANK_ACCOUNT_FORM } from 'graphql/queries/BankAccountFormQueries';
import useQueryWithErrors from 'hooks/useQueryWithErrors';
import _ from 'lodash';
import styles from 'components/Forms/BankAccountForm/BankAccountForm.module.css';

import { bankAccountTypeEnabled } from 'utils/helpers/flags';
import {
  Form,
  Select,
  SelectOption
} from '@justworkshr/milo-form'
import { Button } from '@justworkshr/milo-core'
import FormFieldWrapper from '../FormFieldWrapper';
import FormikFormField from '../FormikFormField';
import FormFooter from '../FormFooter';

type Props = {
  layout: 'vertical' | 'horizontal',
  onBack?: () => void,
  onSubmit: (values: FormikValues) => void;
  submitButtonText?: string;
  isSubmitting: boolean;
  apolloErrors?: ApolloError;
  tosText?: string | ReactElement<any, string | JSXElementConstructor<any>> | null;
  countryCode: string,
  editMode?: boolean,
  setIsEditing?: (isEditing: boolean) => void
}

const AutoForm = ({ onBack, onSubmit, submitButtonText, isSubmitting, apolloErrors, tosText, countryCode, editMode }: Props) => {
  const { t } = useTranslation();
  const initialErrors = useMutationFormErrors(apolloErrors)
  const { generateCountryOptions, generateCurrencyOptions } = useContext(SupportedCountriesContext)

  const [country, setCountry] = useState(countryCode)
  const { data: bankAccountFormData, loading } = useQueryWithErrors<QueryBankAccountFormResType>(
    GET_BANK_ACCOUNT_FORM_QUERY_NAME,
    QUERY_BANK_ACCOUNT_FORM,
    {
      // Skip if we don't yet have a country code
      skip: !country,
      variables: { countryCode: country },
      fetchPolicy: 'no-cache'
    }
  )

  if (!bankAccountFormData && loading) {
    return <Spinner variation='black' />
  }

  const formConfig = bankAccountFormData?.bankAccountForm as FormType

  // Feature flag: hide accountType field until we're ready to display it to users
  if (!bankAccountTypeEnabled()) {
    formConfig.fields = _.filter(formConfig.fields, f => f.name !== 'accountType')
    delete bankAccountFormData?.bankAccountForm?.initialValues?.accountType
  }

  let fields = formConfig.fields
  if (editMode) {
    fields = _.filter(formConfig.fields, f => f.name !== 'nickname')
  }

  let initialFormValues = {
    ...bankAccountFormData?.bankAccountForm?.initialValues,
    country: country,
    // append initial accountType value of "checkout"
    // if accountType value is "" from server response
    // TODO: remove below spread when no longer needed
    ...(bankAccountTypeEnabled() &&
        bankAccountFormData?.bankAccountForm?.initialValues?.accountType === '' &&
        { accountType: 'checking' })
  }

  if (tosText) {
    initialFormValues = {
      ...initialFormValues,
      termsAgreement: false
    }
  }

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={generateFormValidations(formConfig.fields, t, !!tosText)}
      onSubmit={onSubmit}
      validateOnChange={false}
      initialErrors={initialErrors}
      enableReinitialize
    >
      {({ handleBlur, handleChange, handleSubmit, values, errors }: FormikProps<typeof formConfig.initialValues>) => (
        <Form onSubmit={handleSubmit}>
          <div className={FormStyles.formFieldContainer}>
            {
              editMode &&
              _.filter(formConfig.fields, f => f.name === 'nickname').map((field) => (
                  <AutoFormField
                    key={field.name}
                    type={field.type}
                    name={field.name}
                    label={field.label}
                    description={field.description}
                    placeholder={field.placeholder}
                    validations={field?.validations}
                    fields={field.fields?.map((groupField) => ({
                      ...groupField,
                      value: (values[field.name] as { [key: string]: string })[groupField.name] || ''
                    }))}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    defaultValue={field?.defaultValue}
                    value={field.type !== 'group' ? values[field.name] as string : ''}
                    options={field?.options}
                  />
              ))
            }
            {
              !editMode &&
              <div className={FormStyles.formHeaderContainer}>
                <h3>{formConfig.header}</h3>
              </div>
            }

            <FormFieldWrapper>
              <FormikFormField
                name='country'
                label={t('common.country')}
                required
              >
                <Select
                  name='country'
                  key='country'
                  value={values.country as string}
                  onChange={(e: any) => {
                    setCountry(e.currentTarget.value)
                    handleChange(e)
                  }}
                  onBlur={handleBlur}
                >
                  {generateCountryOptions().map((option) => <SelectOption key={option.value} value={option.value}>{option.description}</SelectOption>)}
                </Select>
              </FormikFormField>
            </FormFieldWrapper>

            <FormFieldWrapper>
              <FormikFormField
                name='currency'
                label={t('bankAccount.currency')}
                required
              >
                <Select
                  name='currency'
                  key='currency'
                  value={values.currency as string}
                  onBlur={handleBlur}
                >
                  {generateCurrencyOptions(country).map((option) => <SelectOption key={option.value} value={option.value}>{option.description}</SelectOption>)}
                </Select>
              </FormikFormField>
            </FormFieldWrapper>

            {fields.map((field) => (
              <AutoFormField
                key={field.name}
                type={field.type}
                name={field.name}
                label={field.label}
                description={field.description}
                placeholder={field.placeholder}
                validations={field?.validations}
                fields={
                  field.fields?.map((groupField) => {
                    if (!values[field.name]) {
                      return {
                        ...groupField,
                        value: ''
                      }
                    }

                    return {
                      ...groupField,
                      value: (values[field.name] as { [key: string]: string })[groupField.name] || ''
                    }
                  })
                }
                handleChange={handleChange}
                handleBlur={handleBlur}
                defaultValue={field?.defaultValue}
                value={field.type !== 'group' ? values[field.name] as string : ''}
                options={field?.options}
              />
            ))}
          </div>

          {
            tosText && (
              <div className={FormStyles.tosCheckbox}>
                <CheckboxInput
                  label={tosText}
                  error={!!errors.termsAgreement}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name='termsAgreement'
                  checked={values.termsAgreement as boolean}
                />
                <FormFieldErrorMessage name='termsAgreement' />
              </div>
            )
          }
          {
            editMode && onBack &&
            <FormFooter className={styles.footer}>
              <aside>
                <Button
                  onClick={() => onBack()}
                  mode='secondary'
                  disabled={isSubmitting}
                  type='button'
                  variant='outlined'
                >
                  {submitButtonText || t('common.cancel')}
                </Button>
              </aside>
              <Button loading={isSubmitting} type='submit'>
                {submitButtonText || t('bankAccount.saveChanges')}
              </Button>
            </FormFooter>
          }
          {
            !editMode &&
            <FormFooter className={styles.footer}>
              {
                onBack && (
                  <aside>
                    <Button mode='secondary' type='button' variant='outlined' onClick={onBack}>{t('common.back')}</Button>
                  </aside>
                )
              }
              <Button loading={isSubmitting} type='submit'>
                {submitButtonText || t('common.submit')}
              </Button>
            </FormFooter>
          }
        </Form>
      )}
    </Formik>
  );
}

export default AutoForm
