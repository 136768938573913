import React, { useContext } from 'react';
import styles from './ExportPayHistoryButton.module.css';
import { Button } from '@justworkshr/milo-core';
import {
  useCSVDownloader,
  usePapaParse
} from 'react-papaparse';
import { SupportedCountriesContext } from 'contexts/SupportedCountries';
import { formatDate } from 'utils/helpers/formatDate';
import { GET_CURRENT_MEMBER_DETAILS_QUERY_NAME, QUERY_CURRENT_MEMBER_DETAILS } from 'graphql/queries/MemberDetailQueries';
import useQueryWithErrors from 'hooks/useQueryWithErrors';
import { PaymentType } from 'utils/types/paymentType';

interface ExportPayHistoryButtonProps {
  data: PaymentType[]
}

const ExportPayHistoryButton: React.FC<ExportPayHistoryButtonProps> = ({ data }) => {
  const { CSVDownloader, Type } = useCSVDownloader();
  const { jsonToCSV } = usePapaParse();

  const { data: memberData } = useQueryWithErrors(
    GET_CURRENT_MEMBER_DETAILS_QUERY_NAME,
    QUERY_CURRENT_MEMBER_DETAILS,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const fullName = `${memberData?.currentMemberDetails?.firstName} ${memberData?.currentMemberDetails?.lastName}`

  const { formatCurrency } = useContext(SupportedCountriesContext);

  const generateCSVData = (payments: PaymentType[]) => {
    const json = (payments || []).map(payment => ({
      'Paying Company Name': payment.companyName,
      'Contractor Name': fullName,
      'Debit Amount': formatCurrency(payment.details.collectionAmount, payment.details.collectionCurrency),
      'Debit Currency': payment.details.collectionCurrency.toUpperCase(),
      'Credit Amount': formatCurrency(payment.details.disbursementAmountActual || 0, payment.details.disbursementCurrency),
      'Credit Currency': payment.details.disbursementCurrency.toUpperCase(),
      'Arrival Date': formatDate(payment.details.disbursementSettledAt)
    }))

    return jsonToCSV(json)
  }

  return (
    <div className={styles.exportButtonContainer}>
      <CSVDownloader
        className={styles.csvDownloaderButton}
        type={Type.Button}
        filename={`Justworks-${fullName}-pay history`}
        data={() => generateCSVData(data)}
      >
        <Button variant='outlined' size='sm'>Export</Button>
      </CSVDownloader>
    </div>
  );
};

export default ExportPayHistoryButton;
