import styles from './BankAccount.module.css';
import useQueryWithErrors from '../../hooks/useQueryWithErrors';
import {
  CURRENT_BANK_ACCOUNT_QUERY_NAME, CURRENT_BANK_ACCOUNT_QUERY,
  QueryCurrentBankAccountResType
} from '../../graphql/queries/BankAccountQueries';
import BankAccountForm from '../../components/Forms/BankAccountForm';
import { useContext, useEffect, useState } from 'react';
import { Spinner } from 'assets/milo';
import { SupportedCountriesContext } from '../../contexts/SupportedCountries';
import { AlertTypes, useAppAlerts } from '../../contexts/AppAlerts';
import { useTranslation } from 'react-i18next';
import { bankAccountTypeEnabled } from 'utils/helpers/flags';
import { useLocation, useNavigate } from 'react-router-dom';
import { Badge, Button, PageHeader } from '@justworkshr/milo-core';
import { SystemIcon } from '@justworkshr/milo-icons';

const BankAccount = () => {
  const [isEditing, setIsEditing] = useState(false)
  const { supportedCountryMap } = useContext(SupportedCountriesContext);
  const { pushAlerts } = useAppAlerts();
  const { t } = useTranslation();
  const { data: bankAccountData, loading, refetch } = useQueryWithErrors<QueryCurrentBankAccountResType>(
    CURRENT_BANK_ACCOUNT_QUERY_NAME,
    CURRENT_BANK_ACCOUNT_QUERY,
    {
      // Ensure we're getting fresh data
      fetchPolicy: 'no-cache'
    }
  )
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname.endsWith('edit')) {
      setIsEditing(true)
    } else {
      setIsEditing(false)
    }
  }, [location.pathname])

  const completeUpdate = () => {
    navigate('/bank-account')
    refetch().then(() => {
      const alert = { message: t('bankAccount.updateSuccess'), type: AlertTypes.Success }
      pushAlerts([alert])
    })
  }

  if (loading) { return <Spinner variation='black' /> }

  const renderEditMode = () => {
    return (
      <BankAccountForm
        currentBankAccount={bankAccountData}
        setIsEditing={setIsEditing}
        onBack={() => navigate('/bank-account')}
        onCompleted={completeUpdate}
        countryCode={bankAccountData?.currentBankAccount?.details?.country}
      />
    )
  }

  const renderView = () => {
    const accountType = bankAccountData?.currentBankAccount?.details?.accountType
    // @ts-ignore
    const country = supportedCountryMap[bankAccountData.currentBankAccount.details.country]
    return (
      <div className={styles.cardContent}>
        <div className={styles.bankIconContainer}>
          <div>
            <SystemIcon
              iconName='building'
              color='neutral'
              size='large'
            />
          </div>
        </div>
        <div className={styles.bankInfoContainer}>
          <div className={styles.bankNameContainer}>
            <div className={styles.bankName}>
              <h2>{bankAccountData?.currentBankAccount?.nickname}</h2>
              {bankAccountTypeEnabled() && accountType && (
                <Badge
                  emphasis='subtle'
                  role='info'
                  size='medium'
                >
                  {t(`bankAccount.${accountType}`)}
                </Badge>
              )}
              <label className={styles.bankCountry}>{country.name}</label>
            </div>

            <Button
              className={styles.editButton}
              type='button'
              variant='ghost'
              onClick={() => navigate('/bank-account/edit')}
              size='sm'
            >
              Edit
            </Button>
          </div>

          <div className={styles.bankDataContainer}>
            <div className={bankAccountTypeEnabled() ? styles.contentLabelContainer : undefined}>
              <label className={styles.contentLabel}>Account number</label>
              <div data-dd-privacy='mask'>{bankAccountData?.currentBankAccount?.redactedAccountNumber}</div>
            </div>

            <div className={styles.contentLabelContainer}>
              <label className={styles.contentLabel}>Currency</label>
              <div className={styles.currency}>
                {country.currencyCode.toUpperCase()} {country.currencyName} ({country.currencySymbol})
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div data-testid='bank-account-view'>
      <div className={styles.content}>
        <PageHeader
          className={styles.pageHeader}
          title={'Bank account'}
          border={false}
        >
          { isEditing && t('bankAccount.bankAccountNotice') }
        </PageHeader>

        { (isEditing && bankAccountData) ? renderEditMode() : renderView() }
      </div>
    </div>
  );
};

export default BankAccount;
