import { gql } from '@apollo/client';

export type MemberDetailsType = {
  id: string;
  memberId: string;
  firstName: string;
  lastName: string;
  preferredName: string;
  dateOfBirth: string;
  pronouns: string;
  phoneNumber: string;
  serviceProvided: string;
  website: string;
  accountStatus?: string;
};

export const GET_CURRENT_MEMBER_DETAILS_QUERY_NAME = 'GetAllMemberDetails';

export type QueryCurrentMemberDetailsResType = {
  currentMemberDetails: MemberDetailsType
}

export const QUERY_CURRENT_MEMBER_DETAILS = gql`
  query ${GET_CURRENT_MEMBER_DETAILS_QUERY_NAME} {
    currentMemberDetails {
      id
      memberId
      firstName
      lastName
      preferredName
      dateOfBirth
      pronouns
      phoneNumber
      serviceProvided
      website
      accountStatus
      nationalId
    }
  }
`

export const GET_ALL_MEMBER_DETAILS_QUERY_NAME = 'GetAllMemberDetails';

export type QueryAllMemberDetailsResType = {
  allMemberDetails: MemberDetailsType[]
}

export const QUERY_ALL_MEMBER_DETAILS = gql`
  query ${GET_ALL_MEMBER_DETAILS_QUERY_NAME} {
    allMemberDetails {
      id
      memberId
      firstName
      lastName
      preferredName
      dateOfBirth
      pronouns
      phoneNumber
      serviceProvided
      website
    }
  }
`

export const CREATE_MEMBER_DETAIL_MUTATION_NAME = 'createMemberDetail';

export const ADD_MEMBER_DETAIL = gql`
  mutation($memberId: ID!, $firstName: String!, $lastName: String!,
    $preferredName: String!, $pronouns: String!, $dateOfBirth: ISO8601Date!, $phoneNumber: String!, $serviceProvided: String!, $website: String!) {

    ${CREATE_MEMBER_DETAIL_MUTATION_NAME}(
        input: {
          memberId: $memberId,
          firstName: $firstName,
          lastName: $lastName,
          preferredName: $preferredName,
          pronouns: $pronouns,
          dateOfBirth: $dateOfBirth,
          phoneNumber: $phoneNumber,
          serviceProvided: $serviceProvided,
          website: $website
        }
      ) {
      id
      memberId
      firstName
      lastName
      preferredName
      title
      dateOfBirth
      serviceProvided
      website
    }
  }
`;
