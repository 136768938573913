import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';
import { PaymentsContext } from 'contexts/Payments';
import { SupportedCountriesContext } from 'contexts/SupportedCountries';
import { Spinner } from 'assets/milo';

import styles from './EarningsBanner.module.css';

const EarningsBanner = () => {
  const paymentsContext = useContext(PaymentsContext);
  const paymentsData = paymentsContext?.paymentsData;
  const completedPaymentsCount = paymentsData?.completedPayments?.length;

  const {
    formatCurrency,
    currencySymbolMap,
    supportedCountries
  } = useContext(SupportedCountriesContext);
  const { t } = useTranslation();

  return (
    <div className={styles.earningsBanner}>
      <h4>
        {completedPaymentsCount} {
          completedPaymentsCount === 1 ? t('dashboard.paymentReceived') : t('dashboard.paymentsReceived')
        }
      </h4>
      <div className={styles.total}>
      {
        paymentsData?.currency && currencySymbolMap[paymentsData?.currency] ? (
          <>
            <span className={styles.currencyAmount}>
              {
                (paymentsData?.yearToDateTotal || 0) > 0 ? (
                  <CountUp
                    end={paymentsData?.yearToDateTotal || 0}
                    duration={1.5}
                    formattingFn={(amount) => formatCurrency(
                      amount,
                      paymentsData?.currency
                    )}
                  />
                ) : (
                  formatCurrency(
                    paymentsData?.yearToDateTotal,
                    paymentsData?.currency
                  )
                )
              }
              <span className={styles.currencyLabel}>{paymentsData?.currency}</span>
            </span>
          </>
        ) : (
          <Spinner className={styles.spinner} />
        )
      }
      </div>
      <div className={styles.bannerSubtitle}>
        {'in'} {supportedCountries.find(
          country => country.currencyCode === paymentsData?.currency
        )?.currencyName}
      </div>
    </div>
  )
}

export default EarningsBanner;
