import React from 'react';
import CreateAccount from 'views/CreateAccountView';
import LoggedInLayout from 'views/layouts/LoggedInLayout';
import OnboardingRequired from 'wrappers/OnboardingRequired';
import AuthRequired from 'wrappers/AuthRequired';
import OnboardingPaymentInfo from 'views/OnboardingView/OnboardingPaymentInfoView';
import OnboardingPersonalInfo from 'views/OnboardingView/OnboardingPersonalInfoView';
import PaymentInvitation from 'views/PaymentInvitationView';
import Dashboard from 'views/DashboardView';
import AdminHome from 'views/AdminHomeView';
import OnboardingPersona from 'views/OnboardingView/OnboardingPersonaView/OnboardingPersona';
import SystemDetailsPage from 'views/SystemDetailsView';
import NotFound from 'views/NotFoundView';
import TermsOfService from 'views/TermsOfServiceView';
import { Outlet, useRoutes, Navigate } from 'react-router-dom';
import LoggedOutLayout from 'views/layouts/LoggedOutLayout';
import BankAccount from './views/BankAccountView';
import { documentsEnabled, personaEnabled } from './utils/helpers/flags';
import Payments from 'views/PaymentsView';
import Documents from 'views/DocumentsView';
import BankAccountTypeDialog from 'components/BankAccountTypeDialog';
import Profile from 'views/ProfileView';
import ProfileEdit from 'views/ProfileEditView';

const publicRoutes = [
  {
    element: <LoggedOutLayout/>
  }
]

const adminRoutes = [
  { index: true, element: <AdminHome /> },
  { path: 'system-details', element: <SystemDetailsPage /> }
]

const accountRoutes = [
  { path: 'create', element: <CreateAccount /> },
  { path: 'payment-invitation/:id', element: <PaymentInvitation /> }
]

const accountCreationRoutes = [
  {
    path: 'account',
    element: <LoggedOutLayout/>,
    children: [...accountRoutes]
  }
]

const onboardingRoutes = [
  {
    path: 'personal-info',
    element: <OnboardingPersonalInfo />
  },
  {
    path: 'payment-info',
    element: <OnboardingPaymentInfo />
  }
]

const onboardingRequiredRoutes = [
  {
    element: (
      <OnboardingRequired>
        <>
          <Outlet />
          <BankAccountTypeDialog />
        </>
      </OnboardingRequired>
    ),
    children: [
      { path: '/', element: <Navigate to={documentsEnabled() ? '/dashboard/payments' : '/dashboard'} /> },
      {
        path: 'dashboard',
        element: <Dashboard />,
        children: documentsEnabled() ? [
          {
            index: true,
            element: <Navigate to={'/dashboard/payments'} />
          },
          {
            path: 'payments',
            element: <Payments />
          },
          {
            path: 'documents',
            element: <Documents />
          }
        ] : []
      },
      { path: 'bank-account', element: <BankAccount /> },
      { path: 'bank-account/edit', element: <BankAccount /> },
      { path: 'profile', element: <Profile /> },
      { path: 'profile/edit', element: <ProfileEdit /> }
    ]
  }
]

const authRequiredRoutes = [
  {
    path: '/',
    element: <AuthRequired><LoggedInLayout/></AuthRequired>,
    children: [
      {
        path: 'admin',
        children: [...adminRoutes]
      },
      {
        path: 'onboarding',
        children: personaEnabled() ? [...onboardingRoutes, {
          path: 'persona-verification',
          element: <OnboardingPersona />
        }] : [...onboardingRoutes]
      },
      ...onboardingRequiredRoutes,
      { path: 'login' }
    ]
  }
]

const allRoutes = [
  ...publicRoutes,
  ...accountCreationRoutes,
  ...authRequiredRoutes,
  { path: '/terms-of-service', element: <TermsOfService /> },
  { path: '*', element: <NotFound /> }
];

export const AllRoutes = () => useRoutes(allRoutes);
